<template>
  <div>
    <dynamic-template :name="mainTemplate"></dynamic-template>
    <div class="left-sticky">
      <dynamic-template name="Logo"></dynamic-template>
      <dynamic-template name="Weather2"></dynamic-template>
      <dynamic-template name="Currency2"></dynamic-template>
    </div>
    <dynamic-template name="FooterBar"></dynamic-template>
  </div>
</template>

<script>
import DynamicTemplate from '../components/DynamicTemplate';

export default {
  name: 'layout4',
  props: ['data'],
  components: { DynamicTemplate },
  computed: {
    mainTemplate() {
      return this.$store.state.mainTemplate;
    }
  }
};
</script>

<style lang="scss">
.left-sticky {
  position: fixed;
  top: 0;
  left: 0;
  background: url('/images/parts/left-sticky-bg.png') center top no-repeat;
  background-size: 100% 100%;
  width: 238px;
  height: 100%;
  z-index: 5;
  .logo {
    margin: 30px 0;
    text-align: center;
    img {
      width: 90%;
      height: 164px;
      -o-object-fit: contain;
      object-fit: contain;
      object-position: center;
    }
  }
}
@media (max-width: 1365px) {
}
@media (min-width: 1366px) and (max-width: 1900px) {
}
@media (min-width: 1950px) and (max-width: 2048px) {
}
@media (min-width: 2049px) {
}
.pharmacy {
  .main {
    right: 10px;
    left: 336px;
    .main-content {
      .item {
        max-width: 780px;
        padding: 14px 60px;
      }
      .detail {
        min-width: 584px;
      }
    }
  }
}
.prayer-time .prayer {
  left: 280px;
}
</style>
